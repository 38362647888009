.ion-float-left {
  float: left !important;
}

.ion-float-right {
  float: right !important;
}

.ion-float-start {
  float: left !important;
}
[dir=rtl] .ion-float-start, :host-context([dir=rtl]) .ion-float-start {
  float: right !important;
}

.ion-float-end {
  float: right !important;
}
[dir=rtl] .ion-float-end, :host-context([dir=rtl]) .ion-float-end {
  float: left !important;
}

@media (min-width: 576px) {
  .ion-float-sm-left {
    float: left !important;
  }

  .ion-float-sm-right {
    float: right !important;
  }

  .ion-float-sm-start {
    float: left !important;
  }
  [dir=rtl] .ion-float-sm-start, :host-context([dir=rtl]) .ion-float-sm-start {
    float: right !important;
  }

  .ion-float-sm-end {
    float: right !important;
  }
  [dir=rtl] .ion-float-sm-end, :host-context([dir=rtl]) .ion-float-sm-end {
    float: left !important;
  }
}
@media (min-width: 768px) {
  .ion-float-md-left {
    float: left !important;
  }

  .ion-float-md-right {
    float: right !important;
  }

  .ion-float-md-start {
    float: left !important;
  }
  [dir=rtl] .ion-float-md-start, :host-context([dir=rtl]) .ion-float-md-start {
    float: right !important;
  }

  .ion-float-md-end {
    float: right !important;
  }
  [dir=rtl] .ion-float-md-end, :host-context([dir=rtl]) .ion-float-md-end {
    float: left !important;
  }
}
@media (min-width: 992px) {
  .ion-float-lg-left {
    float: left !important;
  }

  .ion-float-lg-right {
    float: right !important;
  }

  .ion-float-lg-start {
    float: left !important;
  }
  [dir=rtl] .ion-float-lg-start, :host-context([dir=rtl]) .ion-float-lg-start {
    float: right !important;
  }

  .ion-float-lg-end {
    float: right !important;
  }
  [dir=rtl] .ion-float-lg-end, :host-context([dir=rtl]) .ion-float-lg-end {
    float: left !important;
  }
}
@media (min-width: 1200px) {
  .ion-float-xl-left {
    float: left !important;
  }

  .ion-float-xl-right {
    float: right !important;
  }

  .ion-float-xl-start {
    float: left !important;
  }
  [dir=rtl] .ion-float-xl-start, :host-context([dir=rtl]) .ion-float-xl-start {
    float: right !important;
  }

  .ion-float-xl-end {
    float: right !important;
  }
  [dir=rtl] .ion-float-xl-end, :host-context([dir=rtl]) .ion-float-xl-end {
    float: left !important;
  }
}

/*# sourceMappingURL=float-elements.css.map */
