.swiper {
  --bullet-background: var(--ion-color-step-200, #cccccc);
  --bullet-background-active: var(--ion-color-primary, #3880ff);
  --progress-bar-background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --progress-bar-background-active: var(--ion-color-primary-shade, #3171e0);
  --scroll-bar-background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --scroll-bar-background-active: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
  /**
   * @prop --bullet-background: Background of the pagination bullets
   * @prop --bullet-background-active: Background of the active pagination bullet
   *
   * @prop --progress-bar-background: Background of the pagination progress-bar
   * @prop --progress-bar-background-active: Background of the active pagination progress-bar
   *
   * @prop --scroll-bar-background: Background of the pagination scroll-bar
   * @prop --scroll-bar-background-active: Background of the active pagination scroll-bar
   */
  display: block;
  user-select: none;
}

.swiper .swiper-pagination-bullet {
  background: var(--bullet-background);
}

.swiper .swiper-pagination-bullet-active {
  background: var(--bullet-background-active);
}

.swiper .swiper-pagination-progressbar {
  background: var(--progress-bar-background);
}

.swiper .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--progress-bar-background-active);
}

.swiper .swiper-scrollbar {
  background: var(--scroll-bar-background);
}

.swiper .swiper-scrollbar-drag {
  background: var(--scroll-bar-background-active);
}

.swiper .slide-zoom {
  display: block;
  width: 100%;
  text-align: center;
}

.swiper .swiper-slide {
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
}

.swiper .swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

/*# sourceMappingURL=ionic-swiper.css.map */
