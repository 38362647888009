@import "../themes/ionic.skip-warns.scss";
@import "../themes/ionic.globals";
@import "../themes/ionic.mixins";


// Element Space
// --------------------------------------------------
// Creates padding and margin attributes to be used on
// any element

$padding: var(--ion-padding, 16px);
$margin: var(--ion-margin, 16px);

// Padding
// --------------------------------------------------

.ion-no-padding {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;

  @include padding(0);
}

.ion-padding {
  --padding-start: #{$padding};
  --padding-end: #{$padding};
  --padding-top: #{$padding};
  --padding-bottom: #{$padding};

  @include padding($padding);
}

.ion-padding-top {
  --padding-top: #{$padding};

  @include padding($padding, null, null, null);
}

.ion-padding-start {
  --padding-start: #{$padding};

  @include padding-horizontal($padding, null);
}

.ion-padding-end {
  --padding-end: #{$padding};

  @include padding-horizontal(null, $padding);
}

.ion-padding-bottom {
  --padding-bottom: #{$padding};

  @include padding(null, null, $padding, null);
}

.ion-padding-vertical {
  --padding-top: #{$padding};
  --padding-bottom: #{$padding};

  @include padding($padding, null, $padding, null);
}

.ion-padding-horizontal {
  --padding-start: #{$padding};
  --padding-end: #{$padding};

  @include padding-horizontal($padding);
}


// Margin
// --------------------------------------------------

.ion-no-margin {
  --margin-start: 0;
  --margin-end: 0;
  --margin-top: 0;
  --margin-bottom: 0;

  @include margin(0);
}

.ion-margin {
  --margin-start: #{$margin};
  --margin-end: #{$margin};
  --margin-top: #{$margin};
  --margin-bottom: #{$margin};

  @include margin($margin);
}

.ion-margin-top {
  --margin-top: #{$margin};

  @include margin($margin, null, null, null);
}

.ion-margin-start {
  --margin-start: #{$margin};

  @include margin-horizontal($margin, null);
}

.ion-margin-end {
  --margin-end: #{$margin};

  @include margin-horizontal(null, $margin);
}

.ion-margin-bottom {
  --margin-bottom: #{$margin};

  @include margin(null, null, $margin, null);
}

.ion-margin-vertical {
  --margin-top: #{$margin};
  --margin-bottom: #{$margin};

  @include margin($margin, null, $margin, null);
}

.ion-margin-horizontal {
  --margin-start: #{$margin};
  --margin-end: #{$margin};

  @include margin-horizontal($margin);
}