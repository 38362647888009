.ion-hide {
  display: none !important;
}

.ion-hide-up {
  display: none !important;
}

.ion-hide-down {
  display: none !important;
}

@media (min-width: 576px) {
  .ion-hide-sm-up {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  .ion-hide-sm-down {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .ion-hide-md-up {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .ion-hide-md-down {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .ion-hide-lg-up {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .ion-hide-lg-down {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .ion-hide-xl-up {
    display: none !important;
  }
}
@media (max-width: 1199.98px) {
  .ion-hide-xl-down {
    display: none !important;
  }
}

/*# sourceMappingURL=display.css.map */
