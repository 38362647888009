@import "../themes/ionic.skip-warns.scss";
@import "../themes/ionic.globals";
@import "../themes/ionic.mixins";

// Text Transformation
// --------------------------------------------------
// Creates text transform attributes based on screen size

@each $breakpoint in map-keys($screen-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $screen-breakpoints);

  @include media-breakpoint-up($breakpoint, $screen-breakpoints) {
    // Provide `.ion-text-{bp}` classes for transforming the text based
    // on the breakpoint
    .ion-text#{$infix}-uppercase {
      /* stylelint-disable-next-line declaration-no-important */
      text-transform: uppercase !important;
    }

    .ion-text#{$infix}-lowercase {
      /* stylelint-disable-next-line declaration-no-important */
      text-transform: lowercase !important;
    }

    .ion-text#{$infix}-capitalize {
      /* stylelint-disable-next-line declaration-no-important */
      text-transform: capitalize !important;
    }
  }
}
