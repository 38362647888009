.ion-no-padding {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.ion-padding {
  --padding-start: var(--ion-padding, 16px);
  --padding-end: var(--ion-padding, 16px);
  --padding-top: var(--ion-padding, 16px);
  --padding-bottom: var(--ion-padding, 16px);
  padding-left: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 16px);
  padding-top: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-padding {
    padding-left: unset;
    padding-right: unset;
    -webkit-padding-start: var(--ion-padding, 16px);
    padding-inline-start: var(--ion-padding, 16px);
    -webkit-padding-end: var(--ion-padding, 16px);
    padding-inline-end: var(--ion-padding, 16px);
  }
}

.ion-padding-top {
  --padding-top: var(--ion-padding, 16px);
  padding-top: var(--ion-padding, 16px);
}
.ion-padding-start {
  --padding-start: var(--ion-padding, 16px);
  padding-left: var(--ion-padding, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-padding-start {
    padding-left: unset;
    -webkit-padding-start: var(--ion-padding, 16px);
    padding-inline-start: var(--ion-padding, 16px);
  }
}

.ion-padding-end {
  --padding-end: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-padding-end {
    padding-right: unset;
    -webkit-padding-end: var(--ion-padding, 16px);
    padding-inline-end: var(--ion-padding, 16px);
  }
}

.ion-padding-bottom {
  --padding-bottom: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}
.ion-padding-vertical {
  --padding-top: var(--ion-padding, 16px);
  --padding-bottom: var(--ion-padding, 16px);
  padding-top: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}
.ion-padding-horizontal {
  --padding-start: var(--ion-padding, 16px);
  --padding-end: var(--ion-padding, 16px);
  padding-left: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-padding-horizontal {
    padding-left: unset;
    padding-right: unset;
    -webkit-padding-start: var(--ion-padding, 16px);
    padding-inline-start: var(--ion-padding, 16px);
    -webkit-padding-end: var(--ion-padding, 16px);
    padding-inline-end: var(--ion-padding, 16px);
  }
}

.ion-no-margin {
  --margin-start: 0;
  --margin-end: 0;
  --margin-top: 0;
  --margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.ion-margin {
  --margin-start: var(--ion-margin, 16px);
  --margin-end: var(--ion-margin, 16px);
  --margin-top: var(--ion-margin, 16px);
  --margin-bottom: var(--ion-margin, 16px);
  margin-left: var(--ion-margin, 16px);
  margin-right: var(--ion-margin, 16px);
  margin-top: var(--ion-margin, 16px);
  margin-bottom: var(--ion-margin, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-margin {
    margin-left: unset;
    margin-right: unset;
    -webkit-margin-start: var(--ion-margin, 16px);
    margin-inline-start: var(--ion-margin, 16px);
    -webkit-margin-end: var(--ion-margin, 16px);
    margin-inline-end: var(--ion-margin, 16px);
  }
}

.ion-margin-top {
  --margin-top: var(--ion-margin, 16px);
  margin-top: var(--ion-margin, 16px);
}
.ion-margin-start {
  --margin-start: var(--ion-margin, 16px);
  margin-left: var(--ion-margin, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-margin-start {
    margin-left: unset;
    -webkit-margin-start: var(--ion-margin, 16px);
    margin-inline-start: var(--ion-margin, 16px);
  }
}

.ion-margin-end {
  --margin-end: var(--ion-margin, 16px);
  margin-right: var(--ion-margin, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-margin-end {
    margin-right: unset;
    -webkit-margin-end: var(--ion-margin, 16px);
    margin-inline-end: var(--ion-margin, 16px);
  }
}

.ion-margin-bottom {
  --margin-bottom: var(--ion-margin, 16px);
  margin-bottom: var(--ion-margin, 16px);
}
.ion-margin-vertical {
  --margin-top: var(--ion-margin, 16px);
  --margin-bottom: var(--ion-margin, 16px);
  margin-top: var(--ion-margin, 16px);
  margin-bottom: var(--ion-margin, 16px);
}
.ion-margin-horizontal {
  --margin-start: var(--ion-margin, 16px);
  --margin-end: var(--ion-margin, 16px);
  margin-left: var(--ion-margin, 16px);
  margin-right: var(--ion-margin, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-margin-horizontal {
    margin-left: unset;
    margin-right: unset;
    -webkit-margin-start: var(--ion-margin, 16px);
    margin-inline-start: var(--ion-margin, 16px);
    -webkit-margin-end: var(--ion-margin, 16px);
    margin-inline-end: var(--ion-margin, 16px);
  }
}

/*# sourceMappingURL=padding.css.map */
